import collectionRequest from '../queries/collection.json';
import stylesRequest from '../queries/styles.json';
import styleRequest from '../queries/style.json';
import looksRequest from '../queries/looks.json';
import hotspotsForLookRequest from '../queries/hotspotsforlooks.json';
import hotspotRequest from '../queries/hotspot.json';
import productsRequest from '../queries/products.json';
import productRequest from '../queries/product.json';
import roomsRequest from '../queries/rooms.json';
import pageRequest from '../queries/page.json';
import pageRequestByTitle from '../queries/pageByTitle.json';
import startRequest from '../queries/start.json';

import { getFromLocalStorage } from './localstorage';

export const apiURL = import.meta.env.VITE_API_URL || '';
export const apiUser = import.meta.env.VITE_AUTH_USER || '';
export const apiPass = import.meta.env.VITE_AUTH_PASS || '';

export const getLangFromStorage = () => {
    const marketFromStorage = getFromLocalStorage('market') ?? null;
    const marketFromStorageObject = JSON.parse(marketFromStorage);
    return marketFromStorageObject ? marketFromStorageObject.lang : 'de-de';
}

export function getCollectionQuery(collectionCode) {
    let request = {...collectionRequest};
    request.query = request.query.replace("{{collection_code}}", collectionCode);
    return request;
}

export function getStylesQuery() {
    return {...stylesRequest};
}

export function getStyleQuery(uuid) {
    let request = {...styleRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getLooksQuery(uuid) {
    let request = {...looksRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getHotspotsForLookQuery(uuid) {
    let request = {...hotspotsForLookRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getHotspotQuery(uuid) {
    let request = {...hotspotRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getProductsQuery() {
    return {...productsRequest};
}

export function getProductQuery(uuid) {
    let request = {...productRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getRoomsQuery() {
    return {...roomsRequest};
}

export function getPageQuery(uuid) {
    let request = {...pageRequest};
    request.query = request.query.replace("{{uuid}}", uuid);
    return request;
}

export function getPageQueryByTitle(title) {
    let request = {...pageRequestByTitle};
    request.query = request.query.replace("{{title}}", title);
    return request;
}

export function getStartQuery(title) {
    let request = {...startRequest};
    request.query = request.query.replace("{{title}}", title);
    return request;
}

export async function doQuery(requestBody) {
    const langFromStorage = getLangFromStorage();

    console.log('DO QUERY WITH: ', langFromStorage);

    const requestURL = apiURL; // import.meta.env.VUE_APP_API_URL || '';
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(apiUser + ':' + apiPass),
            'X-Language': langFromStorage
        },
        body: JSON.stringify(requestBody)
    };

    try {
        const response = await fetch(requestURL, requestOptions);
        const data = await response.json();
        console.log('data', data);
        return data.result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// export async function doQuery(requestBody) {
//     // const requestURL = process.env.VUE_APP_API_URL;
//     const requestURL = 'https://kirby_dbm.test/api/query';
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             // 'Authorization': 'Basic ' + btoa(process.env.VUE_APP_API_AUTH_USER + ':' + process.env.VUE_APP_API_AUTH_PASS)
//             'Authorization': 'Basic ' + btoa('nh@zeitraum.com:rootroot')
//         },
//         body: JSON.stringify(requestBody)
//     };
//     fetch(requestURL, requestOptions)
//         .then(response => response.json())
//         .then(data => data.result)
//         .catch((error) => {
//             console.error('Error:', error);
//         });
// }