import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getFromLocalStorage } from '../utils/localstorage';

export const useLanguageStore = defineStore('language', () => {
    const marketFromStorage = getFromLocalStorage('market') ?? '';
    const currentLang = ref('de-de');
    const currentCountry = ref('Deutschland');
    const currentLanguageName = ref('Deutsch');
    const isLangOverlayOpen = ref(true);
    if(marketFromStorage !== '') {
        const marketFromStorageObject = JSON.parse(marketFromStorage);
        currentCountry.value = marketFromStorageObject ? marketFromStorageObject.countryLong : 'Deutschland';
        currentLanguageName.value = marketFromStorageObject ? marketFromStorageObject.langName : 'Deutsch';
        currentLang.value = marketFromStorageObject ? marketFromStorageObject.lang : 'de-de';
        isLangOverlayOpen.value = false;
    }
    return { currentCountry, currentLanguageName, currentLang, isLangOverlayOpen }
})